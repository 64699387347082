import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo/seo'
import CaseStudyHero from 'components/case-studies/case-study-hero'
import Hero from 'components/global/hero'
import DynamicBlocks from 'components/dynamic-blocks/dynamic-blocks'
import CarouselCaseStudies from 'components/case-studies/carousel-case-studies'
import GetInTouch from 'components/get-in-touch/get-in-touch'
import './templates.sass'

const Template = ({ data }) => {
  const d = data.allCaseStudiesYaml.edges[0].node

  return (
    <Layout bodyClass="k-dark-hero-page">
      <SEO
        title={d.seoTitle}
        image={d.meta.image}
        metaTitle={d.meta.title}
        keywords={d.meta.keywords}
        description={d.meta.description} />
      <div className="CaseStudy">
        <Hero image={d.hero} className="Hero--case-study-page" alt={`Background image for ${d.title}`}>
          <CaseStudyHero
            className="container-fluid text-center text-light"
            data={d}
            showStats={false}
            showTags />
        </Hero>
        <DynamicBlocks data={d.sections} />
        <CarouselCaseStudies exclude={d.path} />
        <GetInTouch />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    allCaseStudiesYaml(filter: { path: { eq: $path } }) {
      edges {
        node {
          path
          client
          seoTitle
          meta {
            title
            keywords
            image
            description
          }
          title
          logo
          logotext
          tags
          hero
          stats {
            value
            text
          }
          sections {
            classes
            component
            images
            alts
            titleContent
            content
            number
            title
            subtext
          }
        }
      }
    }
  }
`

export default Template
