import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { Carousel } from 'react-responsive-carousel'
import CaseStudyCarouselItem from 'components/case-studies/case-study-carousel-item'
import './carousel-case-studies.sass'

const GetCaseStudies = (excludePath) => {
  let data = useStaticQuery(
    graphql`
      query {
        allCaseStudiesYaml(
          sort: {
            fields: [order],
            order: ASC
          }
        ) {
          edges {
            node {
              path
              client
              title
              logo
              tags
              hero
              stats {
                value
                text
              }
            }
          }
        }
      }
    `
  )

  data = data.allCaseStudiesYaml.edges.filter(edge =>
    edge.node.path !== excludePath
  )

  return data.map((edge, i) => {
    return (
      <div
        key={i}
        className="CarouselCaseStudies__study">
        <CaseStudyCarouselItem
          className="CaseStudyCarouselItem--sm"
          data={edge.node}
          index={i}
          showStats={true}/>
      </div>
    )
  })
}

const getInfo = () => (
  <div className="mb-4 mb-lg-0">
    <h2>Up next</h2>
    <p>View our other extended client case studies&hellip;</p>
    <Link to="/case-studies" className="mt-2 btn btn-sm btn-outline-primary">
      View All
    </Link>
  </div>
)

const CarouselCaseStudies = ({ data, ...props }) => (
  <section className="CarouselCaseStudies k-section k-bg--grey">
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-3">
          {getInfo()}
        </div>
        <div className="col-lg-9">
          <Carousel
            emulateTouch
            showStatus={false}
            showThumbs={false}
            swipeScrollTolerance={50}
            transitionTime={300}>
            {GetCaseStudies(props.exclude)}
          </Carousel>
        </div>
      </div>
    </div>
  </section>
)

export default CarouselCaseStudies
