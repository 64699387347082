import React from 'react'
import { Link } from 'gatsby'
import Tags from 'components/global/tags'
import Image from 'components/image/image'
import './case-study-hero.sass'

const getLogo = (data) => {
  return data.logo ? (
    <>
      <h2 className="text-light font-weight-light">
        {data.logotext ? data.logotext : null}
      </h2>
      <div className="CaseStudyHero__logo mx-auto mb-2">
        <Image image_url={`clients/cs/${data.logo}`} alt={`${data.client} client logo`} />
      </div>
    </>
  ) : (
    <h2 className="CaseStudyHero__logo-text mx-auto mb-2 mt-5 text-light">
      {data.client}
    </h2>
  )
}

const getStats = stats => {
  return stats.map((stat, i) => {
    return (
      <p
        key={i}
        className="px-2 px-sm-4">
        <span
          className="CaseStudyHero__stat-val d-block"
          dangerouslySetInnerHTML={{ __html: stat.value}} />
        <span
          className="k-text--mono small d-block"
          dangerouslySetInnerHTML={{ __html: stat.text}} />
      </p>
    )
  })
}

const CaseStudyHero = ({ data, showStats, ...props}) => (
  <div className={`CaseStudyHero ${props.className ? props.className : ''}`}>
    <div className="CaseStudyHero__content mx-auto k-max-width--3">
      {getLogo(data)}
      {props.showTags &&
        <Tags
          data={data.tags}
          className="
          list-unstyled
          d-md-flex
          m-0
          py-1
          px-4
          text-center
          text-light
          justify-content-center" />
      }
      <h1 className="text-light h2 mt-3 mb-4">
        {data.title}
      </h1>
      <div
        className="CaseStudyHero__stats d-flex justify-content-center">
        {showStats && data.stats ? getStats(data.stats) : null}
      </div>
      {props.withButton &&
        <Link
          to={data.path}
          className="btn btn-sm btn-outline-light mt-4">
          View Case Study
        </Link>
      }
    </div>
  </div>
)

export default CaseStudyHero
