import React from 'react'
import { sendClickedEvent } from 'helpers/analytics/event-tracking'
import CaseStudyHero from './case-study-hero'
import Tags from 'components/global/tags'
import Hero from './../global/hero'
import './case-study-carousel-item.sass'

const CaseStudyCarouselItem = ({ data, showStats, ...props }) => (
  <article
    className={`
      CaseStudyCarouselItem
      ${props.className ? props.className : ''}`}
      onClick={ () => sendClickedEvent(`CaseStudyCarouselItem__${data.client}`) }
    >
    <Hero image={data.hero} className="Hero--case-studies" alt={`Background image for ${data.title}`}>
      <Tags
        data={data.tags}
        className="
          CaseStudyCarouselItem__tags
          list-unstyled
          d-md-flex
          m-0
          py-1
          px-4
          text-center
          text-light
          justify-content-center" />
      <div
        className="
          CaseStudyCarouselItem__wrapper
          d-block
          text-light
          k-link--unstlyed
          text-center">
        <CaseStudyHero
          data={data}
          withButton
          showStats={showStats}/>
      </div>
    </Hero>
  </article>
)

export default CaseStudyCarouselItem
