import React from 'react'

const getTags = tags => {
  if(!tags) return;
  return tags.map((tag, i) => {
    return (
      <React.Fragment key={i}>
        {i > 0 &&
          <li
            className="
              mx-md-1
              k-text--mono
              small
              d-none
              d-md-inline-block">/</li>
        }
        <li
          className="
            mx-md-1
            k-text--mono
            small">
          {tag}
        </li>
      </React.Fragment>
    )
  })
}

const Tags = ({ data, ...props }) => (
  <ul className={props.className}>
    {getTags(data)}
  </ul>
)

export default Tags
